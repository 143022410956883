import { Injectable } from '@angular/core';

import { HitsRepositoryService } from '@service/hits-repository.service';
import {
  RecordHitsRequest,
  RecordHitsRequest_Banner,
  RecordHitsRequest_OutfitStyle,
  RecordHitsRequest_Waterfall,
} from '@proto/store/api/store/misc/hits/hits';

@Injectable({
  providedIn: 'root',
})
export class RecordHitsService {
  constructor(private hitsRepository: HitsRepositoryService) {}

  newsClick(newsId: string): void {
    if (!newsId) {
      return;
    }
    this.hitsRepository.recordHits(RecordHitsRequest.fromPartial({ newsId })).subscribe();
  }

  bannerClick(id: string, hash: string): void {
    if (!id || !hash) {
      return;
    }
    this.hitsRepository
      .recordHits(
        RecordHitsRequest.fromPartial({
          banner: RecordHitsRequest_Banner.fromPartial({
            id,
            hash,
          }),
        })
      )
      .subscribe();
  }

  adClick(adId: string): void {
    if (!adId) {
      return;
    }
    this.hitsRepository.recordHits(RecordHitsRequest.fromPartial({ adId })).subscribe();
  }

  categoryClick(bigCategoryName: string): void {
    if (!bigCategoryName) {
      return;
    }
    this.hitsRepository.recordHits(RecordHitsRequest.fromPartial({ bigCategoryName })).subscribe();
  }

  waterfallClick(itemId: string, hash: string): void {
    if (!itemId || !hash) {
      return;
    }
    this.hitsRepository
      .recordHits(
        RecordHitsRequest.fromPartial({
          waterfall: RecordHitsRequest_Waterfall.fromPartial({ itemId, hash }),
        })
      )
      .subscribe();
  }

  logoClick(): void {
    this.hitsRepository.recordHits(RecordHitsRequest.fromPartial({ logo: true })).subscribe();
  }

  outfitViewed(id: string, hash: string): void {
    if (!id || !hash) {
      return;
    }
    this.hitsRepository
      .recordHits(
        RecordHitsRequest.fromPartial({
          outfitStyle: RecordHitsRequest_OutfitStyle.fromPartial({
            id,
            hash,
          }),
        })
      )
      .subscribe();
  }

  downloadAppClick(): void {
    this.hitsRepository.recordHits(RecordHitsRequest.fromPartial({ downloadApp: true })).subscribe();
  }
}
