/* eslint-disable */

export const protobufPackage = "store.api.misc.hits";

export interface RecordHitsRequest {
  /** 消息編號 */
  newsId?:
    | string
    | undefined;
  /**
   * 首頁輪播
   *
   * @deprecated
   */
  bannerId?:
    | string
    | undefined;
  /** 廣告參數 */
  adId?:
    | string
    | undefined;
  /** 大分類英文名稱 */
  bigCategoryName?:
    | string
    | undefined;
  /** 首頁瀑布流 */
  waterfall?:
    | RecordHitsRequest_Waterfall
    | undefined;
  /** 雜誌穿搭 */
  outfitStyle?:
    | RecordHitsRequest_OutfitStyle
    | undefined;
  /** 首頁輪播 */
  banner?:
    | RecordHitsRequest_Banner
    | undefined;
  /** lativ logo */
  logo?:
    | boolean
    | undefined;
  /** download app bar */
  downloadApp?: boolean | undefined;
}

/** 首頁瀑布流 */
export interface RecordHitsRequest_Waterfall {
  /** Required. 首頁瀑布流項目ID */
  itemId: string;
  /** Required. 圖片 */
  hash: string;
}

/** 雜誌穿搭 */
export interface RecordHitsRequest_OutfitStyle {
  /** Required. 雜誌穿搭ID */
  id: string;
  /** Required. 圖片 */
  hash: string;
}

/** 雜誌穿搭 */
export interface RecordHitsRequest_Banner {
  /** Required. 輪播ID */
  id: string;
  /** Required. 圖片 */
  hash: string;
}

function createBaseRecordHitsRequest(): RecordHitsRequest {
  return {
    newsId: undefined,
    bannerId: undefined,
    adId: undefined,
    bigCategoryName: undefined,
    waterfall: undefined,
    outfitStyle: undefined,
    banner: undefined,
    logo: undefined,
    downloadApp: undefined,
  };
}

export const RecordHitsRequest = {
  fromJSON(object: any): RecordHitsRequest {
    return {
      newsId: isSet(object.newsId) ? String(object.newsId) : undefined,
      bannerId: isSet(object.bannerId) ? String(object.bannerId) : undefined,
      adId: isSet(object.adId) ? String(object.adId) : undefined,
      bigCategoryName: isSet(object.bigCategoryName) ? String(object.bigCategoryName) : undefined,
      waterfall: isSet(object.waterfall) ? RecordHitsRequest_Waterfall.fromJSON(object.waterfall) : undefined,
      outfitStyle: isSet(object.outfitStyle) ? RecordHitsRequest_OutfitStyle.fromJSON(object.outfitStyle) : undefined,
      banner: isSet(object.banner) ? RecordHitsRequest_Banner.fromJSON(object.banner) : undefined,
      logo: isSet(object.logo) ? Boolean(object.logo) : undefined,
      downloadApp: isSet(object.downloadApp) ? Boolean(object.downloadApp) : undefined,
    };
  },

  toJSON(message: RecordHitsRequest): unknown {
    const obj: any = {};
    message.newsId !== undefined && (obj.newsId = message.newsId);
    message.bannerId !== undefined && (obj.bannerId = message.bannerId);
    message.adId !== undefined && (obj.adId = message.adId);
    message.bigCategoryName !== undefined && (obj.bigCategoryName = message.bigCategoryName);
    message.waterfall !== undefined &&
      (obj.waterfall = message.waterfall ? RecordHitsRequest_Waterfall.toJSON(message.waterfall) : undefined);
    message.outfitStyle !== undefined &&
      (obj.outfitStyle = message.outfitStyle ? RecordHitsRequest_OutfitStyle.toJSON(message.outfitStyle) : undefined);
    message.banner !== undefined &&
      (obj.banner = message.banner ? RecordHitsRequest_Banner.toJSON(message.banner) : undefined);
    message.logo !== undefined && (obj.logo = message.logo);
    message.downloadApp !== undefined && (obj.downloadApp = message.downloadApp);
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordHitsRequest>, I>>(base?: I): RecordHitsRequest {
    return RecordHitsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RecordHitsRequest>, I>>(object: I): RecordHitsRequest {
    const message = createBaseRecordHitsRequest();
    message.newsId = object.newsId ?? undefined;
    message.bannerId = object.bannerId ?? undefined;
    message.adId = object.adId ?? undefined;
    message.bigCategoryName = object.bigCategoryName ?? undefined;
    message.waterfall = (object.waterfall !== undefined && object.waterfall !== null)
      ? RecordHitsRequest_Waterfall.fromPartial(object.waterfall)
      : undefined;
    message.outfitStyle = (object.outfitStyle !== undefined && object.outfitStyle !== null)
      ? RecordHitsRequest_OutfitStyle.fromPartial(object.outfitStyle)
      : undefined;
    message.banner = (object.banner !== undefined && object.banner !== null)
      ? RecordHitsRequest_Banner.fromPartial(object.banner)
      : undefined;
    message.logo = object.logo ?? undefined;
    message.downloadApp = object.downloadApp ?? undefined;
    return message;
  },
};

function createBaseRecordHitsRequest_Waterfall(): RecordHitsRequest_Waterfall {
  return { itemId: "", hash: "" };
}

export const RecordHitsRequest_Waterfall = {
  fromJSON(object: any): RecordHitsRequest_Waterfall {
    return {
      itemId: isSet(object.itemId) ? String(object.itemId) : "",
      hash: isSet(object.hash) ? String(object.hash) : "",
    };
  },

  toJSON(message: RecordHitsRequest_Waterfall): unknown {
    const obj: any = {};
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.hash !== undefined && (obj.hash = message.hash);
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordHitsRequest_Waterfall>, I>>(base?: I): RecordHitsRequest_Waterfall {
    return RecordHitsRequest_Waterfall.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RecordHitsRequest_Waterfall>, I>>(object: I): RecordHitsRequest_Waterfall {
    const message = createBaseRecordHitsRequest_Waterfall();
    message.itemId = object.itemId ?? "";
    message.hash = object.hash ?? "";
    return message;
  },
};

function createBaseRecordHitsRequest_OutfitStyle(): RecordHitsRequest_OutfitStyle {
  return { id: "", hash: "" };
}

export const RecordHitsRequest_OutfitStyle = {
  fromJSON(object: any): RecordHitsRequest_OutfitStyle {
    return { id: isSet(object.id) ? String(object.id) : "", hash: isSet(object.hash) ? String(object.hash) : "" };
  },

  toJSON(message: RecordHitsRequest_OutfitStyle): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.hash !== undefined && (obj.hash = message.hash);
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordHitsRequest_OutfitStyle>, I>>(base?: I): RecordHitsRequest_OutfitStyle {
    return RecordHitsRequest_OutfitStyle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RecordHitsRequest_OutfitStyle>, I>>(
    object: I,
  ): RecordHitsRequest_OutfitStyle {
    const message = createBaseRecordHitsRequest_OutfitStyle();
    message.id = object.id ?? "";
    message.hash = object.hash ?? "";
    return message;
  },
};

function createBaseRecordHitsRequest_Banner(): RecordHitsRequest_Banner {
  return { id: "", hash: "" };
}

export const RecordHitsRequest_Banner = {
  fromJSON(object: any): RecordHitsRequest_Banner {
    return { id: isSet(object.id) ? String(object.id) : "", hash: isSet(object.hash) ? String(object.hash) : "" };
  },

  toJSON(message: RecordHitsRequest_Banner): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.hash !== undefined && (obj.hash = message.hash);
    return obj;
  },

  create<I extends Exact<DeepPartial<RecordHitsRequest_Banner>, I>>(base?: I): RecordHitsRequest_Banner {
    return RecordHitsRequest_Banner.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RecordHitsRequest_Banner>, I>>(object: I): RecordHitsRequest_Banner {
    const message = createBaseRecordHitsRequest_Banner();
    message.id = object.id ?? "";
    message.hash = object.hash ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
